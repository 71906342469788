import Vue from 'vue'
import VueRouter from 'vue-router'
// import Util from '../libs/util'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  meta: {
    title: "上海曜宁科技有限公司"
  },
  component: () => import("@/views/home.vue"),
  children: [{
    path: '/',
    meta: {
      title: "上海曜宁科技有限公司"
    },
    component: resolve => require(['../views/web/web.vue'], resolve),
  }, {
    path: '/serve',
    meta: {
      title: "服务"
    },
    component: resolve => require(['../views/serve/index.vue'], resolve),
  }, {
    path: '/support',
    meta: {
      title: "支持"
    },
    component: resolve => require(['../views/support/index.vue'], resolve),
  }, {
    path: '/case',
    meta: {
      title: "案例"
    },
    component: resolve => require(['../views/case/index.vue'], resolve),
  }, {
    path: '/about',
    meta: {
      title: "关于"
    },
    component: resolve => require(['../views/about/index.vue'], resolve),
  }, {
    path: '/news',
    meta: {
      title: '支持'
    },
    component: resolve => require(['../views/news/index.vue'], resolve),
  }, {
    path: '/picList',
    meta: {
      title: '支持'
    },
    component: resolve => require(['../views/picList/index.vue'], resolve),
  }, {
    path: '/supportContant',
    meta: {
      title: '支持'
    },
    component: resolve => require(['../views/support/contant/index.vue'], resolve),
  }, {
    path: '/business',
    meta: {
      title: "商务"
    },
    component: resolve => require(['../views/business/index.vue'], resolve),
  }, {
    path: '/content',
    meta: {
      title: "详情"
    },
    component: resolve => require(['../views/content/index.vue'], resolve),
  }, {
    path: '/honor',
    meta: {
      title: "公司荣誉"
    },
    component: resolve => require(['../views/honor/honor.vue'], resolve),
  }, {
    path: '/recruitment',
    meta: {
      title: "岗位招聘"
    },
    component: resolve => require(['../views/recruitment/recruitment.vue'], resolve),
  }, {
    path: '/product',
    meta: {
      title: "产品"
    },
    component: resolve => require(['../views/product/product.vue'], resolve),
  }, {
    path: '/productList',
    meta: {
      title: "产品"
    },
    component: resolve => require(['../views/productList/productList.vue'], resolve),
  }, {
    path: '/productContent',
    meta: {
      title: "产品详情"
    },
    component: resolve => require(['../views/productList/contant/index.vue'], resolve),
  }, {
    path: '/xbzd',
    meta: {
      title: "低代码搭建平台"
    },
    component: resolve => require(['../views/xbzd/xbzd.vue'], resolve),
  }, {
    path: '/sshx',
    meta: {
      title: "学生综合评价（成长画像）平台"
    },
    component: resolve => require(['../views/sshx/sshx.vue'], resolve),
  }, {
    path: '/xbzp',
    meta: {
      title: "智慧屏幕系统"
    },
    component: resolve => require(['../views/xbzp/xbzp.vue'], resolve),
  }, {
    path: '/xzl',
    meta: {
      title: "智慧物联融合平台"
    },
    component: resolve => require(['../views/xzl/xzl.vue'], resolve),
  }, {
    path: '/zhyj',
    meta: {
      title: "智慧硬件对接"
    },
    component: resolve => require(['../views/zhyj/zhyj.vue'], resolve),
  }, {
    path: '/yxfz',
    meta: {
      title: "教师研修与发展平台"
    },
    component: resolve => require(['../views/yxfz/yxfz.vue'], resolve),
  }, {
    path: '/zhkq',
    meta: {
      title: "智慧考勤系统"
    },
    component: resolve => require(['../views/zhkq/zhkq.vue'], resolve),
  }, {
    path: '/zhst',
    meta: {
      title: "智慧生态系统"
    },
    component: resolve => require(['../views/zhst/zhst.vue'], resolve),
  }, {
    path: '/zhyd',
    meta: {
      title: "智慧阅读系统"
    },
    component: resolve => require(['../views/zhyd/zhyd.vue'], resolve),
  }, {
    path: '/zgzpt',
    meta: {
      title: "中高职学生实习与就业平台"
    },
    component: resolve => require(['../views/zgzpt/zgzpt.vue'], resolve),
  }]

}]
const router = new VueRouter({
  mode: 'history',
  base: `/`,
  routes
})

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// };


export default router


router.beforeEach((to, from, next) => {
  // ViewUI.LoadingBar.start()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // Util.title(to.meta.title)
  next()
})